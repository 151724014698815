import * as React from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import { PhxDeparts } from "./supportfiles/PhoenixDepartures";
import TextField from "@mui/material/TextField";
import { Content } from "./supportfiles/Content";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormFullWidthGrid from "./FormGrid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import ReturnExchange from "./Exchange";
import ResponsiveGrid from "./Grid";
import hotelReservationAttemt from "./supportfiles/HotelFunction";
import Snackbar from "@mui/material/Snackbar";
import Container from "@mui/material/Container";
import BasicModal from "./Dialog";
import { Airlines } from "./supportfiles/Airlines";
import { RpPhxContent } from "./supportfiles/RpPhxContent";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: blueGrey ? "#78909c" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textSizeAdjust: "auto",
  textAlign: "center",
  color: blueGrey ? "#263238" : "#fff",
}));

export default function PhoenixToPenasco(props) {
  const [openSnack, setOpenSnack] = useState(false);
  const [phoenix, setPhoenix] = useState("");
  const [rockyPoint, setRockyPoint] = useState("");
  const [depDate, setDepDate] = useState("");
  const [displayDepDate, setDisplayDepDate] = useState("");
  const [displayReturnDate, setDisplayReturnDate] = useState("");
  const [departCost, setDepartCost] = useState(0);
  const [arriveCost, setArriveCost] = useState(0);
  const [regularPassengerCount, setRegularPassengerCount] = useState(1);
  const [roundTripSwitch, setRoundTripSwitch] = useState(false);
  const [open, setOpen] = useState(false);
  const [openPenascoTimes, setOpenPenascoTimes] = useState(false);
  const [phoenixTime, setPhoenixTime] = useState("");
  const [penascoTime, setPenascoTime] = useState("");
  const [finalEstimate, setFinalEstimate] = useState(0);
  const [roundTripEnabled, setRoundTripEnabled] = useState(true);
  const [deployReturnTimes, setDeployReturnTimes] = useState([]);
  const [disableExtraTime, setDisableExtraTime] = useState(false);
  const [departExtra, setDepartExtra] = useState(0);
  const [arriveExtra, setArriveExtra] = useState(0);
  const [subDepartCost, setSubDepartCost] = useState(0);
  const [subArriveCost, setSubArriveCost] = useState(0);
  const [disableAll, setDisableAll] = useState(false);
  const [checkedSm, setCheckedSm] = useState(false);
  const [checkedMd, setCheckedMd] = useState(false);
  const [checkedXl, setCheckedXl] = useState(false);
  const [daysInSuites, setDaysInSuites] = useState(0);
  const [serviceType, setServiceType] = useState("");
  const [tempTotal, setTempTotal] = useState(0);
  const [messageToSnack, setMessageToSnack] = useState("");
  const [baseFee, setBaseFee] = useState(0);
  const [baseTotal, setBaseTotal] = useState(0);
  const [openModal, setOpenModal] = React.useState(false);
  const [airline, setAirline] = useState("");
  const [terminal, setTerminal] = useState("");
  const [hour, setHour] = useState("");
  const [minute, setMinute] = useState("");
  const [ifSuites, setIfSuites] = useState("");
  const [contentForDest, setContentForDest] = useState(Content);

  const handleAirline = (value) => {
    setAirline(value);
    const data = Airlines.find(({ name }) => name === value);
    const getTerminal = data.text;
    setTerminal(getTerminal);
  };

  const handleHour = (e) => {
    setHour(e.target.value);
  };

  const handleMinute = (e) => {
    setMinute(e.target.value);
  };
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const handleSnack = (e) => {
    setOpenSnack((openSnack) => !openSnack);
    setMessageToSnack(e);
  };

  /*---------------------------------------------------- */
  function finalCost(input1, input2, input3, input4, input5) {
    var value1 = (input1 + input2) * input3;
    var value2 = value1 + input4 + input5;
    var baseCost = input1 + input2;
    setFinalEstimate(value2);
    setBaseFee(baseCost);
    setBaseTotal(value1);
  }

  /*-------------------------------------------------------*/
  const handleVip = (e) => {
    setOpen((open) => !open);
  };
  /*--------------------------------------------------- */
  const handlePhoenixTime = (e) => {
    setPhoenixTime(e.target.value);
  };

  /*------------------------------------------------ */
  const handlePenascoTime = (e) => {
    setPenascoTime(e.target.value);
  };
  const handleContentForDest = (e) => {
    setContentForDest(e);
  };

  /*------------------------------------------- */

  /*-------------------------------------------------------------*/
  const handlePhoenix2 = (value) => {
    if (value === null) {
      window.location.reload();
    }
    if (value === "Phoenix Sky Harbor Airport | Aeropuerto de Phoenix AZ.") {
      handleOpen();
    }
    if (value.includes("Puerto Penasco")) {
      handleContentForDest(RpPhxContent);
    }

    setPhoenix(value);
    const data = PhxDeparts.find(({ Hotels }) => Hotels === value);
    const cost = data.Cost;
    const extra = data.Code;
    const totalDepartCost = cost + extra;
    setDepartCost(cost);
    setDepartExtra(extra);
    setSubDepartCost(totalDepartCost);

    finalCost(cost, arriveCost, regularPassengerCount, extra, arriveExtra);
    setServiceType("Shuttle");
  };
  /*--------------------------------------------------------------- */
  const handleRockyPoint = (value) => {
    setDeployReturnTimes(penascoTimes);
    setDisableExtraTime(true);

    if (value === null) {
      window.location.reload();
    }
    setRockyPoint(value);
    const data = contentForDest.find(({ Hotels }) => Hotels === value);
    const cost = data.Cost;
    const extra = data.Code;
    const totalArriveCost = cost + extra;
    setArriveCost(cost);
    setArriveExtra(extra);
    setSubArriveCost(totalArriveCost);
    finalCost(cost, departCost, regularPassengerCount, departExtra, extra);
  };
  /*---------------------------------------------------------- */
  const handleRegularsCount = (event) => {
    setRegularPassengerCount(event.target.value);
    handleChecked(false);
    if (rockyPoint === "2FRONTERAS SUITES PENASCO") {
      setBaseTotal(baseFee * event.target.value);
      finalCost(
        departCost,
        arriveCost,
        event.target.value,
        departExtra,
        arriveExtra
      );
      setOpen(false);
    } else {
      finalCost(
        departCost,
        arriveCost,
        event.target.value,
        departExtra,
        arriveExtra
      );
      setOpen(false);
    }
  };
  /*---------------------------------------------------------------- */
  const handleRoundTripSwitch = (e) => {
    setRoundTripSwitch((roundTripSwitch) => !roundTripSwitch);
    setOpenPenascoTimes((openPenascoTimes) => !openPenascoTimes);
    setDisableAll((disableAll) => !disableAll);

    if (roundTripEnabled === true) {
      setRoundTripEnabled(false);
      setTempTotal(finalEstimate);
      setFinalEstimate(finalEstimate * 2);
      setOpen(false);
    } else {
      setRoundTripEnabled(true);
      setFinalEstimate(tempTotal);
      handleReturnDate("");
      setPenascoTime("");
    }
    if (rockyPoint === "San Luis") {
      setDeployReturnTimes(sanLuisTimes);
    } else {
      setDeployReturnTimes(penascoTimes);
    }
  };
  /*--------------------------------------------------*/
  const handleDate = (event) => {
    setDepDate(event);
    var s = event.toLocaleString();
    var c = s.slice(4, 16);
    setDisplayDepDate(c);
  };

  /*----------------------------------------------------------*/
  const handleReturnDate = (event) => {
    var s = event.toLocaleString();
    var c = s.slice(4, 16);
    setDisplayReturnDate(c);
    if (rockyPoint === "2FRONTERAS SUITES PENASCO") {
      var days = hotelReservationAttemt(depDate, event);
      setDaysInSuites(days);
      var hotelCost = days * 100;
      setFinalEstimate(finalEstimate + hotelCost);
    }
  };
  /*------------------------------------------------------------------------ */
  const sanLuisTimes = ["7:00 am", "10:00 am", "3:00 pm", "7:00 pm"];
  const penascoTimes = ["6:00 am", "11:00 am", "3:00 pm", "Off"];
  const usToMx = finalEstimate * ReturnExchange();
  /*---------------------------------------------------------------------------------- */

  const handleVipValues = (val) => {
    setRegularPassengerCount(val);
    var preValue1 = baseTotal * val;
    var preValue2 = arriveExtra * val;
    var preValue3 = preValue1 + preValue2;
    var preValue4 = preValue3 + departExtra;
    var preValue5 = preValue4 + arriveCost;
    if (val === sm || rockyPoint === "2FRONTERAS SUITES PENASCO") {
      setServiceType("PREMIUM");
      setFinalEstimate(preValue5);
      handleChecked(val);
    } else {
      setServiceType("Mini-van");
      finalCost(departCost, arriveCost, val, departExtra, arriveExtra);
      handleChecked(val);
    }

    if (val === md || rockyPoint === "2FRONTERAS SUITES PENASCO") {
      setServiceType("PREMIUM");
      setFinalEstimate(preValue5);
      handleChecked(val);
    } else {
      setServiceType("Medium size van");
      finalCost(departCost, arriveCost, val, departExtra, arriveExtra);
      handleChecked(val);
    }

    if (val === xl2 || rockyPoint === "2FRONTERAS SUITES PENASCO") {
      setServiceType("PREMIUM");
      setFinalEstimate(preValue5);
      handleChecked(val);
    } else {
      setServiceType("XL2");
      finalCost(departCost, arriveCost, val, departExtra, arriveExtra);
      handleChecked(val);
    }

    if (val === xl || rockyPoint === "2FRONTERAS SUITES PENASCO") {
      setServiceType("PREMIUM");
      setFinalEstimate(preValue5);
      handleChecked(val);
    } else {
      setServiceType("Full size van");
      finalCost(departCost, arriveCost, val, departExtra, arriveExtra);
      handleChecked(val);
    }
  };

  /*----------------------------------------------------------------------- */
  const handleChecked = (val) => {
    if (val === sm) {
      setCheckedSm(true);
      setCheckedMd(false);
      setCheckedXl(false);
    } else if (val === md) {
      setCheckedSm(false);
      setCheckedMd(true);
      setCheckedXl(false);
    } else if (val === xl) {
      setCheckedSm(false);
      setCheckedMd(false);
      setCheckedXl(true);
    } else if (val === xl2) {
      setCheckedSm(false);
      setCheckedMd(false);
      setCheckedXl(true);
    } else {
      setCheckedSm(false);
      setCheckedMd(false);
      setCheckedXl(false);
    }
  };

  let sm = 6;
  let md = 8;
  let xl = 12;
  let xl2 = 14;
  /*---------------------------------------------------------- */
  const handleSubmitForm = (e) => {
    e.preventDefault();
    if (phoenix === "" || rockyPoint === "" || displayDepDate === "") {
      window.alert("Please check your inputs");
    } else document.getElementById("htmlForm").submit();
  };
  /*-----------VVVVV  RETURN VVVVV---------------------- */
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Container className="animate__fadeIn App-container" maxWidth="md">
          <Grid item xs={12}>
            <Item>
              <Card sx={{ maxWidth: "md" }}>
                <CardActionArea disableRipple>
                  <CardContent disableRipple>
                    <Stack spacing={2}>
                      <Autocomplete
                        disabled={disableAll}
                        freeSolo
                        id="search1"
                        options={PhxDeparts.map((option) => option.Hotels)}
                        onChange={(event, value) => handlePhoenix2(value)}
                        filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Departure location | Punto de partida"
                          />
                        )}
                      />
                      <Autocomplete
                        disabled={disableAll}
                        freeSolo
                        id="search2"
                        options={Content.map((option) => option.Hotels)}
                        onChange={(event, value) => handleRockyPoint(value)}
                        filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Destination | Destino"
                          />
                        )}
                      />
                    </Stack>
                    <br />

                    <hr />
                    <Box sx={{ display: "flex", width: "100%" }}>
                      <Grid xs={2}>
                        <br />
                        <Select
                          name="passenger_count"
                          id="pass_count"
                          label="Pasajeros"
                        >
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                        </Select>
                      </Grid>
                      <Grid xs={6}></Grid>

                      <br />
                      <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
                        <div class="leftDiv">
                          Round trip
                          <br />
                          <label class="toggle-switch">
                            <input
                              type="checkbox"
                              checked={roundTripSwitch}
                              onChange={(e) => handleRoundTripSwitch(e)}
                            />
                            <div class="toggle-switch-background">
                              <div class="toggle-switch-handle"></div>
                            </div>
                          </label>
                        </div>
                      </FormControl>
                    </Box>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Item>
          </Grid>
          <br />

          <BasicModal
            prop1={openModal}
            prop2={handleClose}
            airlineProp={
              <Autocomplete
                freeSolo
                id="airline"
                options={Airlines.map((option) => option.name)}
                onChange={(event, value) => handleAirline(value)}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField {...params} label="Select Airline:" />
                )}
              />
            }
            hourProp={
              <>
                <FormControl
                  sx={{ m: 1, minWidth: 100, height: "55px" }}
                  size="small"
                >
                  <InputLabel id="small-label">Hour</InputLabel>
                  <Select
                    id="hour"
                    labelId="small-label"
                    name="hour"
                    label="Hour"
                    onChange={handleHour}
                  >
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                    <MenuItem value={9}>9</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={11}>11</MenuItem>
                    <MenuItem value={12}>12</MenuItem>
                    <MenuItem value={13}>13</MenuItem>
                    <MenuItem value={14}>14</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={16}>16</MenuItem>
                    <MenuItem value={17}>17</MenuItem>
                  </Select>
                </FormControl>
              </>
            }
            minutesProp={
              <>
                <FormControl
                  sx={{ m: 1, minWidth: 100, height: "55px" }}
                  size="small"
                >
                  <InputLabel id="small-label">Min</InputLabel>
                  <Select
                    id="seconds"
                    labelId="small-label"
                    name="seconds"
                    label="Min"
                    onChange={handleMinute}
                  >
                    <MenuItem value={"00"}>00</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                    <MenuItem value={35}>35</MenuItem>
                    <MenuItem value={40}>40</MenuItem>
                    <MenuItem value={45}>45</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={55}>55</MenuItem>
                  </Select>
                </FormControl>
              </>
            }
          />

          <br />

          {/*    <ResponsiveGrid
              grid1={
                <input
                  onChange={(val) => handleVipValues(sm)}
                  class="checkbox"
                  type="checkbox"
                  checked={checkedSm}
                />
              }
              grid2={
                <input
                  onChange={(val) => handleVipValues(md)}
                  class="checkbox"
                  type="checkbox"
                  checked={checkedMd}
                />
              }
              grid3={
                <input
                  onChange={(val) => handleVipValues(xl2)}
                  class="checkbox"
                  type="checkbox"
                  checked={checkedXl}
                />
              }
            ></ResponsiveGrid>
          
            <br /> */}
          <Grid xs={12}>
            <Grid item xs={6}>
              <Item>
                <Card>
                  <CardActionArea disableRipple>
                    <CardContent disableRipple>
                      <FormControl disableRipple>
                        <Stack spacing={2} sx={{ width: "100%" }}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="Depart date"
                              id="dDate"
                              name="dDate"
                              disablePast="true"
                              value=""
                              onChange={handleDate}
                            />
                          </LocalizationProvider>

                          <FormLabel id="radio-buttons-group-label">
                            Select a departure time:
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={phoenixTime}
                            onChange={handlePhoenixTime}
                          >
                            <FormControlLabel
                              value=" 6:00 am"
                              control={<Radio />}
                              label="6:00 am"
                            />
                            <FormControlLabel
                              value=" 11:00 am"
                              control={<Radio />}
                              label="11:00 am"
                            />
                            <FormControlLabel
                              value=" 3:00 pm"
                              control={<Radio />}
                              label="3:00 pm"
                            />
                            <FormControlLabel
                              value=" 6:00 pm"
                              control={<Radio />}
                              label="6:00 pm"
                            />
                          </RadioGroup>
                        </Stack>
                      </FormControl>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Item>
            </Grid>
            <br />
            {openPenascoTimes && (
              <Grid item xs={6}>
                <Item>
                  <Card>
                    <CardActionArea disableRipple>
                      <CardContent disableRipple>
                        <FormControl>
                          <Stack spacing={2} sx={{ width: "100%" }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                label="Return date"
                                id="returnRef"
                                name="returnRef"
                                disablePast="true"
                                value=""
                                minDate={depDate}
                                onChange={handleReturnDate}
                              />
                            </LocalizationProvider>

                            <FormLabel id="buttons-group-label">
                              Return times
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="buttons-group-label"
                              name="row-radio-buttons-group"
                              value={penascoTime}
                              onChange={handlePenascoTime}
                            >
                              <FormControlLabel
                                value={deployReturnTimes[0]}
                                control={<Radio />}
                                label={deployReturnTimes[0]}
                              />
                              <FormControlLabel
                                value={deployReturnTimes[1]}
                                control={<Radio />}
                                label={deployReturnTimes[1]}
                              />
                              <FormControlLabel
                                value={deployReturnTimes[2]}
                                control={<Radio />}
                                label={deployReturnTimes[2]}
                              />
                              <FormControlLabel
                                disabled={disableExtraTime}
                                value={deployReturnTimes[3]}
                                control={<Radio />}
                                label={deployReturnTimes[3]}
                              />
                            </RadioGroup>
                          </Stack>
                        </FormControl>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Item>
              </Grid>
            )}
          </Grid>
          <br />
          <Grid container rowSpacing={1} xs={12}>
            <div className="dep-wrapper">
              <div className="cont">
                <div className="dep">
                  <table>
                    <caption>Shuttle ready</caption>
                    <thead>
                      <th scope="col">Reservacion de servicio</th>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <b>From:</b> {phoenix}
                        </td>
                        <td>
                          <b>To:</b> {rockyPoint}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Pasajeros:</b>
                        </td>
                        <td>{regularPassengerCount}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Fecha de viaje:</b>
                        </td>
                        <td> {displayDepDate} </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Horario:</b>
                        </td>
                        <td>{phoenixTime}</td>
                      </tr>
                      <tr>
                        <td>Sub Total: {"$" + baseTotal}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <FormFullWidthGrid
              input1={phoenix}
              input1X={rockyPoint}
              input2={regularPassengerCount}
              countCost={"$" + baseTotal}
              input3={displayDepDate}
              input3t={"Time: " + phoenixTime}
              input3X={displayReturnDate}
              input3xt={"Time: " + penascoTime}
              input1xfee={"E/A $" + baseFee}
              input1fee={"$" + departExtra}
              input2extra={"$" + arriveExtra}
              input5X={"USD $" + finalEstimate}
              input6={usToMx.toFixed(2)}
              service={serviceType}
              airlineDisplay={airline}
              terminalDisplay={terminal}
              ifSuites={ifSuites}
              daysInHotel={daysInSuites}
              hourDisplay={hour}
              minuteDisplay={minute}
            />
          </Grid>
          <Snackbar
            open={openSnack}
            message={messageToSnack}
            autoHideDuration={4000}
            onClose={handleSnack}
          />
        </Container>
        <br />
      </Box>
    </>
  );
}
