export default function TimeCounter() {
  function timeConvert(n) {
    // Store the input number of minutes in a variable num
    var num = n;
    // Calculate the total hours by dividing the number of minutes by 60
    var hours = num / 60;
    // Round down the total hours to get the number of full hours
    var rhours = Math.floor(hours);
    // Calculate the remaining minutes after subtracting the full hours from the total hours
    var minutes = (hours - rhours) * 60;
    // Round the remaining minutes to the nearest whole number
    var rminutes = Math.round(minutes);
    // Construct and return a string representing the conversion result
    return rhours +" Hours " + rminutes + " Minutes" ;
  }

  let departure_times = [6, 8, 11, 15, 16.5];
  let now = new Date();
  let now_hour = now.getHours() + now.getMinutes() / 60;

  let next_departure = departure_times.find(function (departure_time) {
    let over = departure_time > now_hour;
    return over;
  });

  if (!next_departure) {
    next_departure = departure_times[0];
  }


  let next_departure_time = next_departure - now_hour;
  let next_departure_minutes = next_departure_time * 60;
  let next_departure_string = timeConvert(next_departure_minutes);

  return next_departure_string;
}
