import React from "react";
import { useState } from "react";
import TimeCounter from "./tricks/TimerCount.js";
import PhoenixToPenasco from "./components/PhoenixToPenasco.js";
import "./S.css";
import "./App.css";

function App() {
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line
  const [openRegular, setOpenRegular] = useState(false);
  const [openDirect, setOpenDirect] = useState(false);
  // eslint-disable-next-line
  const handleRegulars = (e) => {
    slideSign();
    setOpenRegular((openRegular) => !openRegular);

    if (open) {
      setOpen((open) => !open);
    } else {
      if (openDirect) {
        setOpenDirect((openDirect) => !openDirect);
      }
    }
  };

  const [showForm, setShowForm] = useState("form-sleep");


  function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 160;
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }

  window.addEventListener("scroll", reveal);

  function buttonClicked() {
    setShowForm("puff-in-hor");
  }


  function slideSign() {
    var hitTarget = document.getElementById("sliderOut");
    hitTarget.setAttribute("class", "flySign animate__rotateOutUpRight");
  }

  return (
    <div className="App">    
      <div className="dep-wrapper">
        <div className="cont">
          <div className="dep">
            Next departure to Puerto Penasco in: <br />
            {/*this form classname useState is display none when button clicks changes classname to puff-in-hor */}
            <br />
            <b>
              <TimeCounter />
            </b>
          </div>
        </div>
      </div>
      <hr/>
      <br />
      {/*<h4><i>Calculate your trip cost<br/>Obtenga el costo de su viaje </i></h4> */}
      <div class="Layout__PageStyle-sc-1yzufk-0 gAAMyR">
        {/*Groups page */}
        {/* open && <VanGroup></VanGroup> */}
        {/*Regular Passengers Container */}
        {/*This is the 4 button start application selectors */}
        {/*  <PhoenixToPenasco />*/}
        {/* This is the pictures below the selector buttons*/}
        {/*End of app */}

      </div>
    </div>
  );
}

export default App;
